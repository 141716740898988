<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('purchaseCheques.form.addpurchasePayment')"
          :description="$t('purchaseCheques.form.FromHereYouCanCreateaNewpurchasePayment')"
          v-if="$route.name == 'purchaseChequesCreate'"
        />
        <addHeader
          :title="$t('purchaseCheques.form.purchasePaymentadjustment')"
          :description="$t('purchaseCheques.form.FromHereYouCanModifyThepurchasePaymentInformation')"
          v-if="$route.name == 'purchaseChequesEdit'"
        />
        <div class="mb-4 row">
          <connectionInput
            :title="$t('purchaseCheques.form.CheckNumbering')"
            :group="$t('purchaseCheques.form.Groups')"
            v-model="item.invoice_group"
            :disabled="
              $user.admin || $user.role.purchase_cheques_edit_item
                ? false
                : true
            "
            :values="$database.invoiceGroups"
            :hasErorr="errors.invoice_group"
            :error="$t('purchaseCheques.form.ThisFieldIsRequired')"
            v-if="$route.name == 'purchaseChequesCreate'"
          />
          <formInput
            :title="$t('purchaseCheques.form.CheckCode')"
            v-model="item.code"
            :disabled="true"
            v-if="$route.name == 'purchaseChequesEdit'"
            products
          />
          <subFileInput :title="$t('invoices.form.invoiceFile')"
            v-model="item.file"
            :file="item.file"
            :path="'invoices'"
            :hasErorr="errors.file"
            :error="$t('allerts.ChooseASuitableFile')"
          />

          <formInput
            :title="$t('purchaseCheques.form.PaymentNumber')"
            v-model="item.refrance"
            products
          />
          
          <formInput
            :title="$t('purchaseCheques.form.checkHolder')"
            :type="'text'"
            v-model="item.holder_name"
            maxlength="255" 
           />

          <selectInput
            :title="$t('purchaseCheques.form.TypeOfCheck')"
            v-model="item.client_type"
            :disabled="$route.name == 'purchaseChequesEdit' ? true : false"
            :values="[
              { name: $t('purchaseCheques.form.ClientCheck'), value: 0 },
              { name: $t('purchaseCheques.form.SupplierCheck'), value: 1 },
              { name: $t('purchaseCheques.form.PartnerCheck'), value: 2 },
            ]"
          />

          <searchGroupInput
            v-if="item.client_type == 0"
            :type="'customer'"
            :values="$database.customers.filter((el) => el.stat == 1)"
            :inputs="$option.purchaseCheques_customer_data"
          />

          <searchGroupInput
            v-if="item.client_type == 1"
            :type="'supplier'"
            :values="$database.suppliers.filter((el) => el.stat == 1)"
            :inputs="$option.purchaseCheques_customer_data"
          />

          <searchGroupInput
            v-if="item.client_type == 2"
            :type="'partner'"
            :values="$database.partners.filter((el) => el.stat == 1)"
            :inputs="$option.purchaseCheques_customer_data"
          />
          
          <div class="form-group row" :class="'col-md-6'">
            <label :class="'col-md-4'">{{$t('purchaseCheques.form.ConstructionDate')}}</label>
            <div :class="'col-md-7'">
              <div class="icon-input w-100 to-left">
                <span><i class="far fa-calendar"></i></span>
                <VueCtkDateTimePicker
                  :label="$t('purchaseCheques.form.ChooseTimeAndDate')"
                  :button-now-translation="$t('purchaseCheques.form.Now')"
                  v-model="item.created_date"
                  :disabled="
                    $user.admin ||
                    $user.role.purchase_cheques_edit_item
                      ? false
                      : true
                  "
                  format="YYYY-MM-DD hh:mm a"
                  color="#631263"
                  button-color="#631263"
                />
              </div>
            </div>
          </div>

          <div class="form-group row" :class="'col-md-6'">
            <label :class="'col-4'">{{
              $t('purchaseCheques.form.PaymentDate')
            }}</label>
            <div :class="'col-7'">
              <div class="icon-input w-100 to-left">
                <span><i class="far fa-calendar"></i></span>
                <VueCtkDateTimePicker
                  :label="$t('purchaseCheques.form.ChooseTimeAndDate')"
                  :button-now-translation="$t('purchaseCheques.form.Now')"
                  v-model="item.date"
                  format="YYYY-MM-DD hh:mm a"
                  color="#631263"
                  button-color="#631263"
                />
              </div>
            </div>
          </div>
          
          <searchGroupInput
          :type="'project'"
          :values="$database.projects"
          :inputs="[{ 'title':$t('purchaseCheques.form.Project'),'show': 'name'}]"
          v-if="$site.projects_allow"
        />


          <connectionInput
            :disabled="item.project_id == 0"
            :title="$t('purchaseCheques.form.ProjectOutput')"
            :group="$t('purchaseCheques.form.ProjectOutputs')"
            v-model="item.project_item_id"
            :values="$database.projectItems.filter(el => el.project_id == item.project_id)"
            v-if="$site.projects_allow"
          />

          <dvider
            :title="$t('purchaseCheques.form.AccountsInformation')"
            :description="
              $t('purchaseCheques.form.AccountInformationDetails')
            "
          />

          <selectInput
            :title="$t('purchaseCheques.form.checkStatus')"
            v-model="item.stat"
            :values="[
              { name: $t('purchaseCheques.form.pending'), value: 0 },
              { name: $t('purchaseCheques.form.paid'), value: 1 },
              { name: $t('purchaseCheques.form.refused'), value: 2 }
            ]"
          />

          <searchGroupInput
            :type="'safe'"
            :values="$database.safes"
            :inputs="[{ 'show': 'name'}]"
          />

          <monyInput
            :title="$t('purchaseCheques.form.cash')"
            v-model="item.cost"
            :hasErorr="errors.cost"
            :error="$t('allerts.theAmountReceivedMustBeGreaterThan')"
          />

          <monyInput
            :title="$t('purchaseCheques.form.BankFees')"
            v-model="item.bank_fees"
            :disabled="
              $user.admin || $user.role.purchase_cheques_edit_item
                ? false
                : true
            "
          />
          <connectionInput
            :title="$t('purchaseCheques.form.PaymentMethod')"
            :group="$t('purchaseCheques.form.PaymentMethods')"
            v-model="item.payment_method_id"
            :hasAdd="true"
            :open="'addPaymentMethod'"
            :values="$database.paymentMethods"
            :value="item.payment_fee_id"
            :disabled="
              $user.admin || $user.role.purchase_cheques_edit_item
                ? false
                : true
            "
          />
          <checkInput
            :title="$t('purchaseCheques.form.BankTax')"
            :value="item.bank_tax"
            v-model="item.bank_tax"
            :disabled="
              $user.admin || $user.role.purchase_cheques_edit_item
                ? false
                : true
            "
          />
          <monyInput
            :title="$t('purchaseCheques.form.TaxAmount')"
            v-if="item.bank_tax == 1"
            v-model="item.bank_tax_amount"
            :disabled="
              $user.admin || $user.role.purchase_cheques_edit_item
                ? false
                : true
            "
          />
          <dvider
            :title="$t('salesPayments.form.PaymentNotes')"
            :description="$t('salesPayments.form.PaymentNotesDetails')"
          />
          <formTextarea
            :title="$t('purchaseCheques.form.thatAbout')"
            v-model="item.description"
          />
          <formTextarea
            :title="$t('purchaseCheques.form.Note')"
            v-model="item.notes"
          />
          <hr class="mt-5 mb-5 col-12" />
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'purchaseChequesCreate' && !$parent.stopEdit"
          >
            {{ $t('purchaseCheques.form.addpurchasePayment') }}
          </a>
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'purchaseChequesEdit' && !$parent.stopEdit"
          >
            {{ $t('purchaseCheques.form.purchasePaymentadjustment') }}
          </a>
          <loading v-if="$parent.stopEdit"/>
        </div>
      </div>
    </div>
    <!-- / .row -->
    <addPaymentMethod v-if="model == 'addPaymentMethod'"/>
  </div>
</template>
<script>
import axios from "axios";

import formInput from "../elements/add/form/formInput.vue";
import formTextarea from "../elements/add/form/formTextarea.vue";
import connectionInput from "../elements/add/form/connectionInput.vue";
import monyInput from "../elements/add/form/monyInput.vue";
import selectInput from "../elements/add/form/selectInput.vue";
import checkInput from "../elements/add/form/checkInput.vue";
import dvider from "../elements/add/dvider.vue";
import addHeader from "../elements/add/header.vue";
import loading from "../elements/add/loading.vue";
import subFileInput from "../elements/add/form/subFileInput.vue";

import addPaymentMethod from "../elements/popupForms/addPaymentMethod.vue";

import searchGroupInput from "../elements/add/form/searchGroupInput.vue";

import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

export default {
  data() {
    return {
      path: "/purchaseCheques",
      item: {
        invoice_group: this.$option.purchase_cheque_invoice_group_id,
        customer_id: 0,
        supplier_id: 0,
        partner_id: 0,
        project_id: 0,
        project: {},
        project_item_id: 0,
        customer: {
          name: "",
          mobile: "",
          address1: "",
          balance: 0,
        },
        supplier: {
          name: "",
          mobile: "",
          address1: "",
          balance: 0,
        },
        partner: {
          name: "",
          mobile: "",
          address1: "",
          balance: 0,
        },
        client_type: 1,
        payment_method_id: 0,
        date: "",
        stat: 0,
        created_date: "",
        bank_tax: 0,
        safe_id: 0,
        safe :{},
      },
      model: false,
      errors: {
        supplier_id: false,
        cost: false,
        safe_id: false,
        invoice_group: false,
      },
      print: 1,
    };
  },
  mounted() {
    this.$updateDatabase([
      "invoiceGroups",
      "customers",
      "suppliers",
      "projects",
      "projectItems",
      "paymentMethods",
    ]);

    if (this.$route.name == "purchaseChequesEdit") {
      this.$parent.stopEdit = true;
      this.getItem();
    }
    if (this.$route.name == "purchaseChequesCreate") {
      var params = this.$getParams()
      if (params.supplier_id) {
        var supplier = this.$database.suppliers.find(
          (el) => el.id == params.supplier_id
        );
        this.item.supplier_id = params.supplier_id;
        this.item.supplier = supplier;
      }
      this.item.created_date = this.$nowDate()
      this.item.date = this.$nowDate(5)
    }
    var elm = this;
      window.addEventListener("keydown", function (e) {
        if (e.key == "F1" || e.key == "Meta") {
          e.preventDefault();
          elm.saveItem();
        }
    })
  },
  methods: {
    getItem() {
      axios
        .get(this.$linkGnirator(this.path + "/" + this.$route.params.id))
        .then((response) => {
          this.item = response.data;
          this.$parent.stopEdit = false;
          this.item.created_date = this.$dateFormat(this.item.created_date, true)
          this.item.date         = this.$dateFormat(this.item.date, true)
        });
    },
    saveItem() {
      var error = 0;
      if (
        this.item.client_type == 0 &&
        (typeof this.item.customer_id === "undefined" ||
          this.item.customer_id == "")
      ) {
        error = 1;
        this.errors.name = true;
      }
      if (
        this.item.client_type == 1 &&
        (typeof this.item.supplier_id === "undefined" ||
          this.item.supplier_id == "")
      ) {
        error = 1;
        this.errors.name = true;
      }
      if (
        typeof this.item.cost === "undefined" ||
        this.item.cost == "" ||
        this.item.cost < 1
      ) {
        error = 1;
        this.errors.cost = true;
      }
      if (
        this.item.stat == 1 &&
        (typeof this.item.safe_id === "undefined" ||
          this.item.safe_id == 0 ||
          this.item.safe_id == "" ||
          this.item.safe_id == null)
      ) {
        error = 1;
        this.errors.safe_id = true;
      }
      if (this.$route.name == "purchaseChequesCreate") {
        if (
          (typeof this.item.invoice_group === "undefined" ||
            this.item.invoice_group == 0 ||
            this.item.invoice_group == "" ||
            this.item.invoice_group == null) &&
          this.$route.name == "purchaseChequesCreate"
        ) {
          error = 1;
          this.errors.invoice_group = true;
        }
      }
      if (error == 0) {
        this.item.date = this.$fixDate(this.item.date)
        this.item.created_date = this.$fixDate(this.item.created_date)
        if (this.$route.name == "purchaseChequesCreate") {
          this.$parent.aletText = this.$t('allerts.purchaseChequesuccessfullyAdde');
          this.$parent.alertType = "success";
          this.$addToLocalDatabase(this.item, this.path.substring(1));
          if (this.item.client_type == 0) {
            this.item.old_balance = this.item.customer.balance;
          } else if (this.item.client_type == 1) {
            this.item.old_balance = this.item.supplier.balance;
          }
          this.item.new_balance = parseFloat(this.item.old_balance) - parseFloat(this.item.cost);
          
          // change customer balance
          if (this.item.client_type == 0) {
            var customer = this.$database.customers.find((el) => el.id == this.item.customer_id);
            if (customer) {
              customer.balance = this.item.new_balance;
            }
          } else {
            var supplier = this.$database.suppliers.find((el) => el.id == this.item.supplier_id);
            if (supplier) {
              supplier.balance = this.item.new_balance;
            }
          }

          // change safe balance
          if (this.item.stat == 1) {
            var safe = this.$database.safes.find((el) => el.id == this.item.safe_id);
            if (safe) {
              safe.balance = parseFloat(safe.balance) - parseFloat(this.item.cost);
            }
          }

          localStorage.database = JSON.stringify(this.$database);
          
          this.$router.go(-1);
          this.$parent.stopEdit = false;
        } else if (this.$route.name == "purchaseChequesEdit") {
          this.item._method = "PUT";
          this.$parent.stopEdit = true;
          axios
            .post(this.$linkGnirator(this.path + "/" + this.item.id), this.item)
            .then((response) => {
              if (response.data == "safe error") {
                this.$parent.aletText = "الموظف غير مربوط بخزينة";
                this.$parent.alertType = "danger";
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              } else if (response.data == "money error") {
                this.$parent.aletText = "المبلغ غير موجود بالخزينة";
                this.$parent.alertType = "danger";
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              } else {
                this.$parent.aletText =
                  this.$t('allerts.purchasePaymentHasBeenModifiedSuccessfully');
                this.$parent.alertType = "success";
                this.$router.go(-1);
                this.$parent.stopEdit = false;
                return response;
              }
            });
        }
      } else {
        this.$parent.aletText = this.$t('allerts.pleaseMakeSureOfTheInput');
        this.$parent.alertType = "danger";
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
  },
  components: {
    formInput,
    dvider,
    addHeader,
    monyInput,
    selectInput,
    formTextarea,
    connectionInput,
    checkInput,
    addPaymentMethod,
    loading,
    subFileInput,

   searchGroupInput,

    VueCtkDateTimePicker,
  },
};
</script>
